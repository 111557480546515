import { useStaticQuery, graphql } from "gatsby"
export const useMagentoSupportQuery = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allSanityMagentoSupport {
          edges {
            node {
              pageTitle
              metadescription
              heroTitle
              heroSubtitle
              mainTitle
              _rawMainText
              mainBtn
              services1Title
              services2Title
              services3Title
              services4Title
              services5Title
              services6Title
              services7Title
              services8Title
              services9Title
              services10Title
              services11Title
              services12Title
              orangeCtaTitle
              _rawOrangeCtaText
              orangeCtaBtn
              heroImage {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              heroIcon1 {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              mainImg {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              orangeCtaOverlayImg {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              services1Icon {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              services2Icon {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              services3Icon {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              services4Icon {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              services5Icon {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              services6Icon {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              services7Icon {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              services8Icon {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              services9Icon {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              services10Icon {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              services11Icon {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              services12Icon {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return data
}
