import React from "react"
import Button from "./Button"
import PortableText from "@sanity/block-content-to-react"

class MainImage2 extends React.Component {
  render() {

    const { mainBtn, mainTitle, mainText, mainImg } = this.props

    return (
      <div className="my-25 mx-auto font-hairline text-dark">
        <div className="max-w-twelve flex flex-col text-center lg:text-left mx-auto lg:flex-row">
          {/* LEFT CONTAINER */}
          <div
            className="flex-1 flex flex-col"
            style={{ padding: "10px", marginRight: "30px" }}
          >
            <h2 className="mb-5 text-2xl">{mainTitle}</h2>
            <div className="w-110px border border-2 border-green mb-5"></div>
            <PortableText blocks={mainText} />
          </div>
          <div
            className="flex-1 flex flex-col items-center"
            style={{ padding: "10px", marginLeft: "30px" }}
          >
            <img 
            src={mainImg}
            className="mt-6"
            style={{ maxWidth: "350px" }}
            alt={mainTitle}
            />
          </div>
        </div>
        <div className="mx-auto md:p-0 px-5" style={{ marginTop: "30px", maxWidth: "410px" }}>

          <Button btnText={mainBtn} bgColor="bg-green" />

        </div>
      </div>
    )
  }
}

export default MainImage2