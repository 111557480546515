import React from "react"
import Layout from "../components/layout"
import Brands from "../components/Brands"
import Contact from "../components/Contact"
import SEO from "../components/seo"
import SubHero from "../components/SubHero"
import MainImage2 from "../components/MainImage2"
import OrangeCTA from "../components/OrangeCTA"
import { useMagentoSupportQuery } from "../hooks/useMagentoSupportQuery"
import YearsInBusiness from "../components/YearsInBusiness"

const MagentoSupportPage = () => {
  const data = useMagentoSupportQuery()
  const short = data.allSanityMagentoSupport.edges[0].node
  return (
    <Layout>
      <SEO
        title="Magento Support"
      />
      <SubHero
        heroImage={short.heroImage.asset.fluid}
        heroTitle={short.heroTitle}
        heroSubtitle={short.heroSubtitle}
        icon1={short.heroIcon1.asset.fluid.src}
      />
      <MainImage2
        mainTitle={short.mainTitle}
        mainText={short._rawMainText}
        mainImg={short.mainImg.asset.fluid.src}
        mainBtn={short.mainBtn}
      />
      <OrangeCTA
        title={short.orangeCtaTitle}
        text={short._rawOrangeCtaText}
        btn={short.orangeCtaBtn}
        overlayImg={short.orangeCtaOverlayImg.asset.fluid}
       />
      <Brands />
      <YearsInBusiness />
      <Contact />
    </Layout>
  )
}

export default MagentoSupportPage